@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');

#root {
  margin-top: 80px;
  margin-bottom: -80px;
}
html,
body {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Lato', sans-serif;
  line-height: 1.15;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

a {
  text-decoration: none;
  font-family: Arial;
  display: block;
}

button,
.button {
  color: #494949;
  font-weight: 700;
  font-size: 0.875rem;
  text-transform: uppercase;
  text-decoration: none;
  background: #ffffff;
  padding: 15px;
  border: 4px solid #494949;
  transition: all 0.4s ease 0s;
  width: 6.25rem;
  text-align: center;
}

button:hover,
.button:hover {
  color: #ffffff;
  background: #a1c503;
  border-color: #a1c503;
  transition: all 0.4s ease 0s;
  cursor: pointer;
}
