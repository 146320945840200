.hero-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30rem;
  background-image: url('../../../images/city.jpg');
  background-size: cover;
  background-size: auto;
}

.hero-img h1 {
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  margin-top: 4.6875rem;
}

.hero-container {
  display: flex;
  max-width: 1200px;
  min-width: 300px;
  margin: 3% auto;
  padding-right: 25px;
  padding-left: 25px;
}

.search-city-bg {
  position: relative;
  background: rgba(0, 0, 0, 0.75);
  height: 50%;
  width: 50%;
  min-width: 31.25rem;
}

.search-city-btn-container {
  position: absolute;
  display: flex;
  align-content: center;
  justify-content: center;
  bottom: 20%;
  left: 50%;
  height: 2.5rem;
  transform: translateX(-50%);
}

.fa-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d3ff00c2;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0 2%;
}

.fa-icon {
  color: #008000;
}

.search-city-btn {
  width: 30vw;
  padding: 0 2%;
  border: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
}
