.favorite {
    margin-left: 15px;
    width: 800px;
    display: flex;
    flex-flow: column wrap;
}

.cities {
    width: 280px;
    margin: 10px;
    padding: 15px;
    background-color: blanchedalmond;
}

.data {
    /* margin-left: 350px; */
    width: 300px;
    margin-top: -70px;
    margin-left: 350px;
}