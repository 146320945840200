@import url(https://fonts.googleapis.com/css2?family=Lato&display=swap);
.favorite {
    margin-left: 15px;
    width: 800px;
    display: flex;
    flex-flow: column wrap;
}

.cities {
    width: 280px;
    margin: 10px;
    padding: 15px;
    background-color: blanchedalmond;
}

.data {
    /* margin-left: 350px; */
    width: 300px;
    margin-top: -70px;
    margin-left: 350px;
}
.hero-img {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30rem;
  background-image: url(/static/media/city.c59b4903.jpg);
  background-size: cover;
  background-size: auto;
}

.hero-img h1 {
  color: #fff;
  font-size: 3rem;
  font-weight: 700;
  text-align: center;
  margin-top: 4.6875rem;
}

.hero-container {
  display: flex;
  max-width: 1200px;
  min-width: 300px;
  margin: 3% auto;
  padding-right: 25px;
  padding-left: 25px;
}

.search-city-bg {
  position: relative;
  background: rgba(0, 0, 0, 0.75);
  height: 50%;
  width: 50%;
  min-width: 31.25rem;
}

.search-city-btn-container {
  position: absolute;
  display: flex;
  align-content: center;
  justify-content: center;
  bottom: 20%;
  left: 50%;
  height: 2.5rem;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

.fa-icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #d3ff00c2;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 0 2%;
}

.fa-icon {
  color: #008000;
}

.search-city-btn {
  width: 30vw;
  padding: 0 2%;
  border: none;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  cursor: pointer;
}

.sign-in-widget {
  margin-top: 12.5rem;
}

.livability-container {
  border-radius: 10px;
  box-shadow: 7px 7px 10px gray;
  width: 350px;
  height: 350px;
  margin-left: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 5;
  background-color: lightgray;
}

.main-score {
  display: flex;
  flex-flow: col wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0;
}

.sub-scores {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.main-score button {
  background-color: lightgray;
  border: 0px;
}

.main-score button:hover {
  background-color: lightgray;
}

.map-container {
  position: absolute;
  top: 80px;
  bottom: 0;
  width: 100%;
  overflow: hidden;
}

.map-container button {
  padding: 0;
  border: none;
}

.geocoder {
  position: fixed;
  z-index: 2;
  width: 50%;
  left: 50%;
  margin-left: -25%;
  top: 20px;
}
.mapboxgl-ctrl-geocoder {
  min-width: 100%;
}

nav {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  background: #fafafa;
  width: 100%;
  height: 80px;
  z-index: 2;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.8);
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

nav button:nth-child(1) {
  margin-right: 25px;
}

.logo {
  margin-left: 25px;
  width: 20vw;
}

.logo img {
  max-width: 100%;
  height: auto;
}

.user-pic {
  border: 1px solid #000;
  border-radius: 25px;
}

.menu-container {
  position: relative;
}
.menu {
  /* background: #ffffff; */
  border-radius: 0.5rem;
  position: absolute;
  top: 3.75rem;
  right: 0;
  width: 18.75rem;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  -webkit-transform: translateY(-1.25rem);
          transform: translateY(-1.25rem);
  transition: opacity 0.4s ease-in-out, visibility 0.4s, -webkit-transform 0.4s ease-in-out;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out,
    visibility 0.4s;
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out,
    visibility 0.4s, -webkit-transform 0.4s ease-in-out;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateY(0);
          transform: translateY(0);
  background: #fafafa;
  padding: 0.3125rem;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-bottom: 1px solid #dddddd;
}

.menu li:last-child {
  border: none;
}

.menu li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.menu-item {
  text-decoration: none;
  color: #333333;
  padding: 0.9375rem 1.25rem;
  display: block;
  cursor: pointer;
}

.menu-trigger {
  border-radius: 5.625rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.375rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease-in-out;
  width: 12.5rem;
}

.menu-trigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.menu-trigger span {
  font-weight: 700;
  font-size: 0.875rem;
  margin: 0 0.625rem;
}

.menu-trigger img {
  border-radius: 5.625rem;
}

#root {
  margin-top: 80px;
  margin-bottom: -80px;
}
html,
body {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Lato', sans-serif;
  line-height: 1.15;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

img {
  border-style: none;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

a {
  text-decoration: none;
  font-family: Arial;
  display: block;
}

button,
.button {
  color: #494949;
  font-weight: 700;
  font-size: 0.875rem;
  text-transform: uppercase;
  text-decoration: none;
  background: #ffffff;
  padding: 15px;
  border: 4px solid #494949;
  transition: all 0.4s ease 0s;
  width: 6.25rem;
  text-align: center;
}

button:hover,
.button:hover {
  color: #ffffff;
  background: #a1c503;
  border-color: #a1c503;
  transition: all 0.4s ease 0s;
  cursor: pointer;
}

