.map-container {
  position: absolute;
  top: 80px;
  bottom: 0;
  width: 100%;
  overflow: hidden;
}

.map-container button {
  padding: 0;
  border: none;
}

.geocoder {
  position: fixed;
  z-index: 2;
  width: 50%;
  left: 50%;
  margin-left: -25%;
  top: 20px;
}
.mapboxgl-ctrl-geocoder {
  min-width: 100%;
}
