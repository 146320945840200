nav {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  background: #fafafa;
  width: 100%;
  height: 80px;
  z-index: 2;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.8);
}

.button-container {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
}

nav button:nth-child(1) {
  margin-right: 25px;
}

.logo {
  margin-left: 25px;
  width: 20vw;
}

.logo img {
  max-width: 100%;
  height: auto;
}

.user-pic {
  border: 1px solid #000;
  border-radius: 25px;
}
