.menu-container {
  position: relative;
}
.menu {
  /* background: #ffffff; */
  border-radius: 0.5rem;
  position: absolute;
  top: 3.75rem;
  right: 0;
  width: 18.75rem;
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-1.25rem);
  transition: opacity 0.4s ease-in-out, transform 0.4s ease-in-out,
    visibility 0.4s;
}

.menu.active {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
  background: #fafafa;
  padding: 0.3125rem;
}

.menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.menu li {
  border-bottom: 1px solid #dddddd;
}

.menu li:last-child {
  border: none;
}

.menu li:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.menu-item {
  text-decoration: none;
  color: #333333;
  padding: 0.9375rem 1.25rem;
  display: block;
  cursor: pointer;
}

.menu-trigger {
  border-radius: 5.625rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0.375rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.3);
  border: none;
  vertical-align: middle;
  transition: box-shadow 0.4s ease-in-out;
  width: 12.5rem;
}

.menu-trigger:hover {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.3);
}

.menu-trigger span {
  font-weight: 700;
  font-size: 0.875rem;
  margin: 0 0.625rem;
}

.menu-trigger img {
  border-radius: 5.625rem;
}
