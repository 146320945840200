.livability-container {
  border-radius: 10px;
  box-shadow: 7px 7px 10px gray;
  width: 350px;
  height: 350px;
  margin-left: 20px;
  margin-bottom: 20px;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  bottom: 0;
  left: 5;
  background-color: lightgray;
}

.main-score {
  display: flex;
  flex-flow: col wrap;
  justify-content: space-between;
  width: 100%;
  margin: 0;
}

.sub-scores {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

.main-score button {
  background-color: lightgray;
  border: 0px;
}

.main-score button:hover {
  background-color: lightgray;
}
